/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React from 'react';
import Layout from '../components/layout';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import Container from '../components/Container';
import * as analytics from '../utils/analytics';

const ThankYouPage = ({ pageContext, location }) => {
  analytics.usePageCategory('lomakkeet');
  const localize = useSelector(state => state.localize);
  const translate = getTranslate(localize);
  const isClaimDamages = location && location.state && location.state.claimDamages;
  const noDefaultText = location && location.state && location.state.noDefault;
  const message = location && location.state && location.state.message;

  return (
    <Layout title={translate('thankYou')} paths={pageContext.paths} locale={pageContext.locale || 'en'}>
      {isClaimDamages ? (
        <Container variant="parcel" sx={{ py: [3, 4] }}>
          <Styled.h1>{translate('claimDamagesForm.thanksTitle')}</Styled.h1>
          <Styled.p>{translate('claimDamagesForm.thanks1')}</Styled.p>
          <Styled.p>{translate('claimDamagesForm.thanks2')}</Styled.p>
          <Styled.p>{translate('claimDamagesForm.thanks3')}</Styled.p>
        </Container>
      ) : (
        <Container variant="parcel" sx={{ py: [3, 4] }}>
          <Styled.h1>{translate('thankYou')}</Styled.h1>
          {message ? <div>{message}</div> : <Styled.p>{translate('thankYouDefault')}</Styled.p>}
        </Container>
      )}
    </Layout>
  );
};

export default ThankYouPage;
